import React from 'react';
import { ValidationRule } from 'antd/lib/form/Form';
import { withTranslation, Trans } from 'react-i18next';
import {
  Form, Input, Button, Checkbox, Row, Col,
} from 'antd';
import './styles.scss';
import { Props, State } from './types';

class RegistrationForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      checkboxChecked: false,
      showPasswordMessages: false,
      showConfirmPasswordMessages: false,
    };
  }

  componentDidMount(): void {
    const { form } = this.props;
    form.validateFields();
  }

  handleSubmit(event: React.FormEvent): void {
    event.preventDefault();
    const { form, registerUser } = this.props;
    const {
      firstName,
      lastName,
      password,
    } = form.getFieldsValue();
    registerUser(firstName, lastName, password);
  }

  toggleCheckboxState(): void {
    this.setState((prevState) => ({
      checkboxChecked: !prevState.checkboxChecked,
    }));
  }

  validatePasswordsMatch(rule: ValidationRule, value: string, callback: Function): void {
    const { form, t } = this.props;
    if (form.getFieldValue('password') === form.getFieldValue('confirm')) {
      callback();
    } else {
      callback(<div className="validation-error">{t('RegistrationForm.password.error', 'Passwords do not match.')}</div>);
    }
  }

  validatePasswordConfirmation(rule: ValidationRule, value: string, callback: Function): void {
    const { form } = this.props;
    form.validateFields(['confirm'], { force: true });
    callback();
  }

  render(): JSX.Element {
    const {
      inputPayload,
      form,
      t,
    } = this.props;
    const {
      checkboxChecked,
      showPasswordMessages,
      showConfirmPasswordMessages,
    } = this.state;

    const inputIsValid = Object.values(form.getFieldsError())
      .filter((value) => value !== undefined).length === 0;
    const submitEnabled = checkboxChecked && inputIsValid;
    const formFieldsIDs = {
      firstName: 'firstName',
      lastName: 'lastName',
      email: 'email',
      password: 'password',
      confirm: 'confirm',
    };
    const formValidationRules = {
      containsLowercase: {
        pattern: new RegExp('[a-z]'),
        message: (<div className="validation-error">{t('RegistrationForm.validationError.lowercase', 'Must contain lowercase letters.')}</div>),
      },
      containsTwoEmbeddedNumbers: {
        pattern: new RegExp('^.+[0-9].*[0-9].+$'),
        message: (<div className="validation-error">{t('RegistrationForm.validationError.numbers', 'Must contain at least 2 embedded numbers.')}</div>),
      },
      leadAndTrailLetter: {
        pattern: new RegExp('^[a-zA-Z].*[a-zA-Z]$'),
        message: (<div className="validation-error">{t('RegistrationForm.validationError.startEndLetter', 'Must begin and end with a letter.')}</div>),
      },
      maxLength15: {
        max: 15,
        message: (<div className="validation-error">{t('RegistrationForm.validationError.maxChar15', 'Maximum of 15 characters.')}</div>),
      },
      maxLength50: {
        max: 50,
        message: (<div className="validation-error">{t('RegistrationForm.validationError.maxChar50', 'Maximum of 50 characters.')}</div>),
      },
      maxLength254: {
        max: 254,
        message: (<div className="validation-error">{t('RegistrationForm.validationError.maxChar254', 'Maximum of 254 characters.')}</div>),
      },
      minLength3: {
        min: 3,
        message: (<div className="validation-error">{t('RegistrationForm.validationError.minChar3', 'Minimum of 3 characters.')}</div>),
      },
      minLength8: {
        min: 8,
        message: (<div className="validation-error">{t('RegistrationForm.validationError.minChar8', 'Minimum of 8 characters.')}</div>),
      },
      noLeadingSpaces: {
        pattern: new RegExp('^\\S'),
        message: (<div className="validation-error">{t('RegistrationForm.validationError.noLeadingSpaces', 'No leading spaces.')}</div>),
      },
      noNumbers: {
        pattern: new RegExp('^[^0-9]+$'),
        message: (<div className="validation-error">{t('RegistrationForm.validationError.noNum', 'No numbers.')}</div>),
      },
      noTrailingSpaces: {
        pattern: new RegExp('\\S$'),
        message: (<div className="validation-error">{t('RegistrationForm.validationError.trailingSpaces', 'No trailing spaces.')}</div>),
      },
      required: {
        required: true,
        message: (<div className="validation-error">{t('RegistrationForm.validationError.reqField', 'Required field.')}</div>),
      },
      validNameCharacters: {
        pattern: new RegExp("^[a-zA-Z0-9\\.,\\-'\\s]+$"),
        message: (<div className="validation-error">{t('RegistrationForm.validationError.nameSpecialChar', 'No special characters except period (.), comma (,), dash (-) or apostrophe (&apos;).')}</div>),
      },
      validPasswordCharacters: {
        pattern: new RegExp('^[a-zA-Z0-9\\.@\\-_]+$'),
        message: (<div className="validation-error">{t('RegistrationForm.validationError.passwordSpecialChar', 'No special characters except period (.), at sign (@), dash (-) or underscore (_).')}</div>),
      },
      validatePasswordConfirmation: {
        validator: (rule: ValidationRule, value: string, callback: Function):
          void => this.validatePasswordConfirmation(rule, value, callback),
      },
      validatePasswordsMatch: {
        validator: (rule: ValidationRule, value: string, callback: Function):
          void => this.validatePasswordsMatch(rule, value, callback),
      },
    };
    const fieldDecoratorOptions = {
      [formFieldsIDs.email]: {
        initialValue: (inputPayload && inputPayload.email) || 'development@emailtest.com',
        rules: [
          formValidationRules.required,
          formValidationRules.minLength3,
          formValidationRules.maxLength254,
        ],
      },
      [formFieldsIDs.firstName]: {
        initialValue: (inputPayload && inputPayload.givenName) || 'John',
        rules: [
          formValidationRules.required,
          formValidationRules.maxLength50,
          formValidationRules.noLeadingSpaces,
          formValidationRules.noTrailingSpaces,
          formValidationRules.noNumbers,
          formValidationRules.validNameCharacters,
        ],
      },
      [formFieldsIDs.lastName]: {
        initialValue: (inputPayload && inputPayload.familyName) || 'Doe',
        rules: [
          formValidationRules.required,
          formValidationRules.maxLength50,
          formValidationRules.noLeadingSpaces,
          formValidationRules.noTrailingSpaces,
          formValidationRules.noNumbers,
          formValidationRules.validNameCharacters,
        ],
      },
      [formFieldsIDs.password]: {
        rules: [
          formValidationRules.required,
          formValidationRules.minLength8,
          formValidationRules.maxLength15,
          formValidationRules.containsLowercase,
          formValidationRules.containsTwoEmbeddedNumbers,
          formValidationRules.leadAndTrailLetter,
          formValidationRules.validPasswordCharacters,
          formValidationRules.validatePasswordConfirmation,
        ],
      },
      [formFieldsIDs.confirm]: {
        rules: [
          formValidationRules.required,
          formValidationRules.validatePasswordsMatch,
        ],
      },
    };
    return (
      <Form
        className="RegistrationForm"
        onSubmit={(event: React.FormEvent): void => this.handleSubmit(
          event,
        )}
      >
        <h2 className="title">{t('RegistrationForm.form.title', 'Complete Registration')}</h2>
        <span className="text">
          <Trans i18nKey="RegistrationForm.form.text">
            You have been invited to collaborate in REDshare.
            <br />
            Please create a password to continue.
          </Trans>
        </span>
        <Form.Item
          label={t('RegistrationForm.form.label.email', 'Email')}
          colon={false}
          hasFeedback
        >
          {
            form.getFieldDecorator(
              formFieldsIDs.email,
              fieldDecoratorOptions.email,
            )(<Input placeholder={t('RegistrationForm.form.label.email', 'Email')} disabled />)
          }
        </Form.Item>
        <Row justify="start" gutter={[10, 0]}>
          <Col span={12}>
            <Form.Item
              label={t('RegistrationForm.form.label.firstName', 'First Name')}
              colon={false}
              hasFeedback
            >
              {
            form.getFieldDecorator(
              formFieldsIDs.firstName,
              fieldDecoratorOptions.firstName,
            )(<Input placeholder={t('RegistrationForm.form.label.firstName', 'First Name')} disabled />)
          }
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('RegistrationForm.form.label.lastName', 'Last Name')}
              colon={false}
              hasFeedback
            >
              {
            form.getFieldDecorator(
              formFieldsIDs.lastName,
              fieldDecoratorOptions.lastName,
            )(<Input placeholder={t('RegistrationForm.form.label.lastName', 'Last Name')} disabled />)
          }
            </Form.Item>
          </Col>
        </Row>
        <Row justify="start" gutter={[10, 0]}>
          <Col span={12}>
            <Form.Item label={t('RegistrationForm.form.label.password', 'Password')} colon={false}>
              {form.getFieldDecorator(
                formFieldsIDs.password,
                fieldDecoratorOptions.password,
              )(
                <div className="input-container">
                  <Input.Password
                    visibilityToggle
                    placeholder={t('RegistrationForm.form.label.password', 'Password')}
                    onFocus={(): void => this.setState({
                      showPasswordMessages: true,
                      showConfirmPasswordMessages: false,
                    })}
                    onBlur={(): void => this.setState({ showPasswordMessages: false })}
                  />
                </div>,
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('RegistrationForm.form.label.confirmPassword', 'Confirm Password')} colon={false}>
              {form.getFieldDecorator(
                formFieldsIDs.confirm,
                fieldDecoratorOptions.confirm,
              )(
                <div className="input-container">
                  <Input.Password
                    visibilityToggle
                    placeholder={t('RegistrationForm.form.label.confirmYourPassword', 'Confirm your password')}
                    onFocus={(): void => this.setState({
                      showConfirmPasswordMessages: true,
                      showPasswordMessages: false,
                    })}
                    onBlur={(): void => this.setState({ showConfirmPasswordMessages: false })}
                  />
                </div>,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row justify="start" gutter={[10, 0]}>
          <Col span={24}>
            <div className="errors has-error">
              <div className="ant-form-explain">
                {/* Add password validation messages here */}
                {showPasswordMessages
                && form.getFieldError(formFieldsIDs.password)?.map((error, index) => (
                  <div key={index} className="error-visible">
                    {error}
                  </div>
                ))}
                {/* Add confirmPassword validation messages here */}
                { showConfirmPasswordMessages
                && form.getFieldError(formFieldsIDs.confirm)?.map((error, index) => (
                  <div key={index} className="error-visible">
                    {error}
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
        <Checkbox
          className="accept-eula"
          checked={checkboxChecked}
          onChange={(): void => this.toggleCheckboxState()}
        >
          <Trans i18nKey="RegistrationForm.form.label.acceptEULA">
            I Accept EULA Terms & Conditions.
            { /* eslint-disable-next-line global-require */}
            <a href={require('assets/terms-of-use.pdf')} target="blank" id="EULALink"> Click here to view.</a>
          </Trans>
        </Checkbox>
        <Form.Item>
          <Button
            disabled={!submitEnabled}
            type="primary"
            shape="round"
            htmlType="submit"
            id={submitEnabled ? 'btn-primary' : 'btn-primary-disabled'}
          >
            {t('RegistrationForm.submitButton', 'Join REDshare')}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedRegistrationForm = Form.create<Props>({ name: 'register' })(RegistrationForm);

export default withTranslation()(WrappedRegistrationForm);

/** @module components/SplashPage */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import { ReactComponent as REDshareLogoPrimary } from '../../assets/redshare-primary.svg';
import { ReactComponent as GEMeatball } from '../../assets/ge-primary-blue.svg';
import { Props } from './types';
import './styles.css';

/**
 * A branded page that displays a message to the user.
 * @param props The props
 * @return A JSX Element
 */
function SplashPage(props: Props): JSX.Element {
  const { children, className } = props;
  const { t } = useTranslation();
  return (
    <div className={`SplashPage ${className || ''}`}>
      <div className="header">
        <div className="logo">
          <REDshareLogoPrimary />
        </div>
        <div className="tagline">
          {t('SplashPage.tagline', 'Export Control Data Sharing')}
        </div>
      </div>
      <div className="content">
        <Card className="card">
          {children}
        </Card>
      </div>
      <div className="footer">
        <div className="presented-by-text">
          {t('SplashPage.presentedBy', 'Presented by')}
        </div>
        <GEMeatball className="meatball" />
      </div>
    </div>
  );
}

export default SplashPage;

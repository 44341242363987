/* @module utilities/jwt */
import { JwtPayload } from './types';
/**
 * Parses a payload from the given JWT token.
 * @param jwt A JWT token
 * @return A payload object
 * @throws An Error if parsing the JWT fails
 */
function parsePayload(jwt: string): JwtPayload {
  const tokenParts = jwt.split('.');
  const encodedPayload = tokenParts[1];
  const decodedPayload = atob(encodedPayload);
  const payload = JSON.parse(decodedPayload);
  return {
    exp: payload.exp,
    iat: payload.iat,
    iss: payload.iss,
    jti: payload.jti,
    sub: payload.sub,
    countryCode: payload['redshare.ge.com/invitation/country_code'],
    email: payload['redshare.ge.com/invitation/email'],
    givenName: payload['redshare.ge.com/invitation/given_name'],
    familyName: payload['redshare.ge.com/invitation/family_name'],
  };
}

export default parsePayload;

import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import queryString from 'query-string';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { parsePayload, payloadHasAllFields, payloadIsExpired } from 'utilities/jwt';
import { JwtPayload } from 'utilities/jwt/types';
import LandingPage from 'components/LandingPage';
import SuccessSplashPage from '../SuccessSplashPage';
import ErrorSplashPage from '../ErrorSplashPage';
import Loader from '../Loader';
import ContactsClient from '../../services/invitations';
import NotFoundSplashPage from '../NotFoundSplashPage';
import 'index.scss';
import 'styles/overrides/index.scss';
import './styles.css';

export default function App(): JSX.Element {
  const [jwtPayload, setJwtPayload] = useState<JwtPayload | undefined>();
  const [jwtIsValid, setJwtIsValid] = useState(false);
  const [jwtIsExpired, setJwtIsExpired] = useState(false);
  const [apiStatusCode, setApiStatusCode] = useState<number | undefined>();
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  const getToken = (): string | null => {
    const { location } = window;
    const { token } = queryString.parse(location.search);
    if (typeof token === 'string') {
      return token;
    }
    return null;
  };

  useEffect(() => {
    const token = getToken();
    let payload;
    try {
      if (token) {
        payload = parsePayload(token);
        setJwtPayload(payload);
      }
      if (payloadHasAllFields(payload)) {
        setJwtIsValid(true);
      }
      if (payload && payloadIsExpired(payload)) {
        setJwtIsExpired(true);
      }
      setLoading(false);
    } catch (error) {
      setJwtIsValid(false);
      setLoading(false);
    }
  }, []);

  const registerUser = (firstName: string, lastName: string, password: string): void => {
    setLoading(true);
    const token = getToken();
    if (token) {
      new ContactsClient().createUser(firstName, lastName, password, token)
        .then((res) => {
          setApiStatusCode(res);
          setLoading(false);
        })
        .catch((err) => {
          notification.error({
            message: t('App.notification.error', 'Unknown server response'),
            description: err.toString(),
            duration: 0,
          });
          setLoading(false);
        });
    } else {
      notification.error({
        message: t('App.notification.error', 'Unknown server response'),
        description: '',
        duration: 0,
      });
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader loading />;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/"
          render={(): JSX.Element => {
            if (apiStatusCode) {
              if (apiStatusCode === 200) {
                return <SuccessSplashPage />;
              }
              return <ErrorSplashPage responseCode={apiStatusCode} />;
            }
            if (jwtIsExpired) {
              return <ErrorSplashPage jwtIsExpired={jwtIsExpired} />;
            }
            if (jwtIsValid) {
              return (
                <LandingPage
                  inputPayload={jwtPayload}
                  registerUser={registerUser}
                />
              );
            }
            return <NotFoundSplashPage />;
          }}
        />
        <Route component={NotFoundSplashPage} />
      </Switch>
    </BrowserRouter>
  );
}

import React from 'react';
import SplashPage from 'components/SplashPage';
import { useTranslation } from 'react-i18next';
import { hostToEnv } from 'utilities/link';
import './styles.css';

function SuccessSplashPage(): JSX.Element {
  const { t } = useTranslation();
  const responsePlainText = t('SuccessSplashPage.response', 'REDshare has successfully registered you as an external user.');
  const displayLink = hostToEnv(window.location.hostname);
  return (
    <SplashPage>
      <div className="SuccessSplashPage">
        {responsePlainText}
        <div>
          {t('SuccessSplashPage.message', 'You may login at: ')}
          <a target="_blank" rel="noopener noreferrer" href={displayLink}>
            {displayLink}
          </a>
        </div>
      </div>
    </SplashPage>
  );
}

export default SuccessSplashPage;

/** @module utilities/link */
/**
 * Returns the correct environment for the given host.
 * @param host The host
 * @return the correct environment for the given host
 */
function hostToEnv(host: string): string {
  switch (host) {
    case 'localhost':
      return 'http://local.ec.ge.com';
    case 'dev-ext-registration.redshare.ge.com':
      return 'https://dev.redshare.ge.com';
    case 'stage-ext-registration.redshare.ge.com':
      return 'https://stage.redshare.ge.com';
    case 'uat-ext-registration.redshare.ge.com':
      return 'https://uat.redshare.ge.com';
    case 'ext-registration.redshare.ge.com':
      return 'https://redshare.ge.com';
    default:
      return 'https://redshare.ge.com';
  }
}

export default hostToEnv;

import React from 'react';
import SplashPage from 'components/SplashPage';
import { useTranslation } from 'react-i18next';
import { hostToEnv } from 'utilities/link';
import { Empty } from 'antd';
import { Props } from './types';
import './styles.css';

function ErrorSplashPage(props: Props): JSX.Element {
  const { responseCode, jwtIsExpired } = props;
  const { t } = useTranslation();
  let heading;
  let description;
  if (jwtIsExpired) {
    heading = t('ErrorSplashPage.heading.expiredJWT', 'Expired Invitation');
    description = t('ErrorSplashPage.description.expiredJWT', 'Your access token has expired. Contact your GE sponsor to request a new REDshare invitation.');
  } else if (responseCode === 409) {
    // backend code for user already exists
    heading = t('ErrorSplashPage.heading.409', 'Already Registered');
    description = t('ErrorSplashPage.description.409', 'There was an error adding you as a new external user. You are already registered with REDshare.');
  } else {
    // generic error
    heading = t('ErrorSplashPage.heading.default', 'Error');
    description = t('ErrorSplashPage.description.default', 'There was an error adding you as a new external user, please refresh the page, verify the inputs, and try again.');
  }
  const displayLink = hostToEnv(window.location.hostname);
  return (
    <SplashPage>
      <div className="ErrorSplashPage">
        <h1>{heading}</h1>
        <Empty description={description} />
        {responseCode === 409
          ? (
            <p>
              {t('ErrorSplashPage.message', 'You may login at: ')}
              <a target="_blank" rel="noopener noreferrer" href={displayLink}>
                {displayLink}
              </a>
            </p>
          )
          : null}
      </div>
    </SplashPage>
  );
}

export default ErrorSplashPage;

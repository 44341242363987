/* @module utilities/jwt */
import { JwtPayload } from './types';
/**
 * Returns true if the given JWT payload is expired.
 * @param payload A JWT payload
 * @return true if the given JWT payload is expired
 */
function payloadIsExpired(payload: JwtPayload): boolean {
  const { exp } = payload;
  const currentTimeUnixSec = Date.now() / 1000;
  if (exp && currentTimeUnixSec < exp) {
    return false;
  }
  return true;
}

export default payloadIsExpired;

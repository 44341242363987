/** @module components/Header */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GELogo } from 'assets/images/ge-monogram-white.svg';
import { ReactComponent as RedshareLogo } from 'assets/images/redshare-knock-out.svg';
import './styles.scss';

function Header(): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <header className="Header">
        <div className="branding">
          <GELogo />
          <Link to="/" className="link">
            <RedshareLogo width={200} height={56} />
            <div className="tagline">
              {t('Header.logo.tagline', 'Regulated, Export and Defense Data Share')}
            </div>
          </Link>
        </div>
      </header>
    </>
  );
}

export default Header;

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Loader from 'components/Loader';
import App from 'components/App';
import { unregister } from './registerServiceWorker';
import './i18n';
import 'antd/dist/antd.css';

ReactDOM.render(
  <Suspense fallback={<Loader loading />}>
    <App />
  </Suspense>,
  document.getElementById('root'),
);
unregister();

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import SplashPage from 'components/SplashPage';
import './styles.css';

const NotFoundSplashPage = (): JSX.Element => {
  const { t } = useTranslation();
  const descriptionText = (
    <span>
      { t('NotFoundSplashPage.message.authIssue', 'There was a problem authenticating your invitation. Please try again.')}
    </span>
  );
  return (
    <SplashPage>
      <h1>{t('NotFoundSplashPage.message.title', 'Page Not Found')}</h1>
      <Empty description={descriptionText} />
      <p>{t('NotFoundSplashPage.message.body', 'Contact your GE sponsor to request a new REDshare invitation.')}</p>
    </SplashPage>
  );
};

export default NotFoundSplashPage;

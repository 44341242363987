/**
 * A client that communicates with the REDShare invitations service
 * using the fetch function.
 */
class FetchClient {
  /**
   * Constructs a new client with the given API level.
   *
   * @param apiLevel The API level this client uses
   */
  private prefix: string;

  constructor(apiLevel = '1.0') {
    this.prefix = `/${apiLevel}`;
  }

  createUser(
    givenName: string,
    familyName: string,
    password: string,
    jwtToken: string,
  ): Promise<number> {
    const uri = `${this.prefix}/users`;
    const body = JSON.stringify({
      name: {
        given: givenName,
        family: familyName,
      },
      password,
    });
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-redshare-invitation-api-key': jwtToken,
      },
      body,
    };
    return fetch(uri, options).then((response) => response.status);
  }
}

export default FetchClient;

/** @module components/TestimonyCard */
import React from 'react';
import {
  Row, Col, Card, Avatar, Carousel,
} from 'antd';
import { useTranslation } from 'react-i18next';
import StephanieValderramaImage from '../../assets/images/stephanie-valderrama.jpg';
import './styles.scss';

function TestimonyCard(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="TestimonyCard">
      <Row justify="center">
        <Col className="testimonyCardContainer" span={24}>
          <Carousel
            autoplay
            autoplaySpeed={10000}
            dots={false}
          >
            <Card className="testimonyCard">
              <div className="testimonyCardContent">
                <div className="avatarContainer">
                  <Avatar
                    className="avatar"
                    size={64}
                    src={StephanieValderramaImage}
                  />
                </div>
                <div className="testimonyContainer">
                  <span className="testimony">{t('TestimonyCard.testimony1', '“Libraries helps my team keep our folders and documents secure for our work with military contracts.”')}</span>
                  <span className="testimonyName">{t('TestimonyCard.testimonyName1', 'Stephanie Valderrama, Project Manager, GE Nuclear')}</span>
                </div>
              </div>
            </Card>
            <Card className="testimonyCard">
              <div className="testimonyCardContent">
                <div className="avatarContainer">
                  <Avatar
                    className="avatar"
                    size={64}
                    icon="user"
                  />
                </div>
                <div className="testimonyContainer">
                  <span className="testimony">{t('TestimonyCard.testimony2', '“Easy to set up... good distribution and sharing. Supports military aircraft (Chile, Singapore). Manage services support contract or maintenance and repair”')}</span>
                  <span className="testimonyName">{t('TestimonyCard.testimonyName2', 'Senior Contract Performance Manager')}</span>
                </div>
              </div>
            </Card>
            <Card className="testimonyCard">
              <div className="testimonyCardContent">
                <div className="avatarContainer">
                  <Avatar
                    className="avatar"
                    size={64}
                    icon="user"
                  />
                </div>
                <div className="testimonyContainer">
                  <span className="testimony">{t('TestimonyCard.testimony3', '“Surprised at how quickly new folder can be set up. Handle lots of data... LR, US-citizen only, and/or classified”')}</span>
                  <span className="testimonyName">{t('TestimonyCard.testimonyName3', 'Engineering Operations Management')}</span>
                </div>
              </div>
            </Card>
          </Carousel>
        </Col>
      </Row>
    </div>
  );
}

export default TestimonyCard;

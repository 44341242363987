/* @module utilities/jwt */

import { JwtPayload } from './types';

/**
 * Returns true if the given JWT payload has all required fields.
 * @param payload A JWT payload
 * @return true if the given JWT payload has all required fields
 */
function payloadHasAllFields(payload?: JwtPayload): boolean {
  if (payload
    && typeof payload.givenName === 'string'
    && typeof payload.familyName === 'string'
    && typeof payload.email === 'string'
    && typeof payload.countryCode === 'string'
    && typeof payload.exp === 'number'
    && typeof payload.iss === 'string'
    && typeof payload.iat === 'number'
    && typeof payload.jti === 'string'
  ) {
    return true;
  }
  return false;
}

export default payloadHasAllFields;
